import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"
import _imports_0 from '@/assets/image/authority/auth_hugeboy.png'
import _imports_1 from '@/assets/image/authority/auth_scanbox.png'
import _imports_2 from '@/assets/image/authority/auth_logo.png'


const _hoisted_1 = { class: "authority" }
const _hoisted_2 = { class: "authority__fix" }
const _hoisted_3 = { class: "authority__box" }
const _hoisted_4 = { class: "authority__scan" }
const _hoisted_5 = { id: "dd-scan" }
const _hoisted_6 = ["src"]
const _hoisted_7 = { class: "authority__tip" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _cache[3] || (_cache[3] = _createElementVNode("div", { class: "authority__boy" }, [
        _createElementVNode("img", {
          src: _imports_0,
          alt: "",
          draggable: "false"
        })
      ], -1)),
      _createElementVNode("div", _hoisted_3, [
        _cache[2] || (_cache[2] = _createElementVNode("img", {
          src: _imports_1,
          alt: "box",
          draggable: "false"
        }, null, -1)),
        _createElementVNode("div", _hoisted_4, [
          _cache[0] || (_cache[0] = _createElementVNode("img", {
            src: _imports_2,
            alt: "logo",
            draggable: "false"
          }, null, -1)),
          _cache[1] || (_cache[1] = _createElementVNode("div", { class: "authority__scan--largetext" }, "设备激活", -1)),
          _createElementVNode("div", _hoisted_5, [
            _createElementVNode("img", {
              src: 'data:image/png;base64,' + _ctx.scanCodeImg 
            }, null, 8, _hoisted_6)
          ]),
          _createElementVNode("div", _hoisted_7, " 请使用" + _toDisplayString(_ctx.channelSwitch === 'WX' ? '企业微信' : '钉钉') + "扫码验证 ", 1)
        ])
      ])
    ])
  ]))
}