
import {defineComponent, onBeforeUnmount, onMounted, watch} from "vue";
import {message} from 'ant-design-vue';
import {authorityBill} from "./Authority";

export default defineComponent({
  name: "Authority",
  setup() {
    let {
      refData,
      getAuthInfo,
      init,
    } = authorityBill()
    let timeout;

    onMounted(() => {
      message.success('正在校验授权信息...');
      init();
      timeout = setInterval(()=>{
        getAuthInfo();
      }, 5000)
    })

    onBeforeUnmount(()=> {
      clearInterval(timeout);
    })


    return {
      ...refData,
    }


  },
  data() {
    return {}
  },
  mounted() {
    this.init();
  },
  methods: {
    init() {

    },
  },
})
